import clsx from 'clsx';
import * as React from 'react';

type SerivceStatusProps = {
  status: string;
  className?: string;
};

const ServiceStatus: React.FC<SerivceStatusProps> = ({ status, className }) => {
  const statusClassName =
    'border bg-opacity-25 text-center w-fit h-auto max-h-auto rounded-full px-3';
  const primaryStyle = 'text-primary-blue-100 bg-primary-blue-100 border-primary-blue-100';
  const pendingStyle = 'text-dark-yellow bg-secondary-yellow-100 border-secondary-yellow-100';
  const rejectedStyle = 'text-red bg-red border-red';

  switch (status) {
    case 'PUBLIC_APPROVED':
      return <div className={clsx(className, statusClassName, primaryStyle)}>Public</div>;
    case 'EAP_APPROVED':
      return <div className={clsx(className, statusClassName, primaryStyle)}>EAP</div>;
    case 'PENDING':
      return <div className={clsx(className, statusClassName, pendingStyle)}>Pending</div>;
    case 'REJECTED':
      return <div className={clsx(className, statusClassName, rejectedStyle)}>Rejected</div>;
    case 'ARCHIVED':
      return <div className={clsx(className, statusClassName, rejectedStyle)}>Archived</div>;
    case 'BOTH_APPROVED':
      return (
        <div className='flex'>
          <div className={clsx(className, statusClassName, primaryStyle, 'mr-2')}>Public</div>
          <div className={clsx(className, statusClassName, primaryStyle)}>EAP</div>
        </div>
      );
    default:
      return null;
  }
};

export default ServiceStatus;
