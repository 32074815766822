/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component } from 'react';
import {
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Table,
  ListGroup,
} from 'reactstrap';
import moment from 'moment';
import 'moment-timezone';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { HelpCircle, RejectBookingModal, EditBookingModal, RepeatModal } from 'common';

import GroupContact from './GroupContact';
import InvoiceLink from '../../pages/practitioner-portal/Invoice/InvoiceLink';
import BookingStatusLabel from '../../pages/practitioner-portal/Bookings/BookingCard/BookingStatusLabel';
import HorizontalSeparator from '../HorizontalSeparator';
import StatusAlert from './StatusAlert';
import SessionLimitText from '../../pages/practitioner-portal/Bookings/SessionLimitText';

const Small = styled.small`
  min-width: max-content;
  margin-right: ${(props) => props.marginRight};
`;

@withRouter
@inject('calendar', 'bookings', 'auth')
@observer
class ViewBookingModal extends Component {
  @observable modalType = 'create';
  @observable editAppointmentModalOpen = false;
  @observable editAppointmentMode = null;
  @observable rejectModalOpen = false;
  @observable rejectFormState = {};
  @observable repeatModalOpen = false;
  @observable cancelDropdownOpen = false;
  @observable editDropdownOpen = false;
  @observable isAccepting = false;
  @observable isDeleting = false;

  getModalHeader = () => {
    const { bookingStatus } = this.props;

    switch (bookingStatus) {
      case 'Block':
        return 'View Blocked Appointment';
      case 'Confirmed':
        return 'View Confirmed Appointment';
      case 'Pending':
        return 'View Pending Appointment';
      case 'Manual':
        return 'Confirm Appointment';
      case 'PendingCompletion':
        return 'View Confirmed Appointment';
      case 'Completed':
        return 'View Completed Appointment';
      default:
        return '';
    }
  };

  renderContact = (event, isGroup = false) => {
    return <GroupContact key={event.id} isGroup={isGroup} event={event} open={true} />;
  };

  renderGroups = (contacts) => {
    if (contacts && contacts.length > 0) {
      return contacts.map((x) => this.renderContact(x, true));
    }
    return <p>No attendees</p>;
  };

  getModalBody = () => {
    const { booking, bookingStatus } = this.props;

    if (bookingStatus === 'Block') {
      const momentStart = moment(booking.blockageTime);
      const momentEnd = moment(booking.blockageEndTime);

      const dateStart = momentStart.format('DD/MM/YY');
      const start = momentStart.format('h:mm A');
      const dateEnd = momentEnd.format('DD/MM/YY');
      const end = momentEnd.format('h:mm A');

      const displaySource = booking.source && booking.source !== 'Clearhead';
      const source = `Synced from ${booking.source}`;

      const title = booking.title;

      const multiDay = !momentStart.isSame(momentEnd, 'day');
      const displayDate = multiDay ? `${dateStart} - ${dateEnd}` : dateStart;

      return (
        <Row className='mb-4'>
          <Col className='col-12'>
            <Table className='mb-0' bordered>
              <tbody>
                <tr>
                  <td>
                    <Label className='h5 font-weight-bold'>Title</Label>
                  </td>
                  <td>
                    <Label className='h5 text-break'>{title}</Label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label className='h5 font-weight-bold'>Date</Label>
                  </td>
                  <td>
                    <Label className='h5 text-break'>{displayDate}</Label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Label className='h5 font-weight-bold'>Start Time</Label>
                  </td>
                  <td>
                    <Label className='h5 text-break'>{start}</Label>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Label className='h5 font-weight-bold'>End Time</Label>
                  </td>
                  <td>
                    <Label className='h5 text-break'>{end}</Label>
                  </td>
                </tr>

                {displaySource && (
                  <tr>
                    <td>
                      <Label className='h5 font-weight-bold'>Source</Label>
                    </td>
                    <td>
                      <Label className='h5 text-break'>{source}</Label>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      );
    }

    let {
      appointments,
      appointmentTime,
      appointmentEndTime,
      appointmentTimeUTC,
      appointmentEndTimeUTC,
      contact,
      serviceLocation,
      paymentOptions,
      clientTimezone,
      partnerBooking,
      partnerOrganisation,
      therapyFundedBy,
      caseNumber,
      caseSessionNumber,
    } = booking;

    const date = moment(appointmentTime).format('dddd Do MMM, YYYY');
    const start = moment(appointmentTime).format('h:mm A');

    const therapistTimezone = this.props.auth.currentPractitioner.timezone;
    const clientTimezoneToUse = clientTimezone ? clientTimezone : therapistTimezone;

    const end =
      moment(appointmentEndTime).format('h:mm A') +
      ' ' +
      moment.tz(this.props.auth.currentPractitioner.timezone).zoneAbbr();

    const clientDateDisplay = `${moment
      .utc(appointmentTimeUTC)
      .clone()
      .tz(clientTimezoneToUse)
      .format('dddd Do MMM, YYYY')}
      `;

    const clientTimeDisplay = `${moment
      .utc(appointmentTimeUTC)
      .clone()
      .tz(clientTimezoneToUse)
      .format('h:mm A - ')}      
    ${moment.utc(appointmentEndTimeUTC).clone().tz(clientTimezoneToUse).format('h:mm A')} ${moment
      .tz(clientTimezoneToUse)
      .zoneAbbr()}
    `;

    return (
      <>
        <HorizontalSeparator className='mt-2 mb-4'>Appointment Details</HorizontalSeparator>
        <Card className='bg-light mb-5'>
          <CardBody>
            <Row>
              <Col>
                <strong className='d-block text-secondary'>Date</strong>
                <Label className='text-break'>
                  {appointmentTime ? (
                    date
                  ) : (
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.editBooking();
                      }}
                    >
                      Set Date
                    </a>
                  )}
                </Label>
                <strong className='d-block text-secondary'>Time</strong>
                <Label className='text-break'>
                  {appointmentTime ? (
                    `${start} - ${end}`
                  ) : (
                    <a
                      href='#'
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.editBooking();
                      }}
                    >
                      Set Time
                    </a>
                  )}
                </Label>
                {therapistTimezone !== clientTimezoneToUse ? (
                  <>
                    <strong className='d-block text-secondary'>Time for Client</strong>
                    <Label className='text-break'>{clientDateDisplay}</Label>
                    <Label className='d-block text-break'>{clientTimeDisplay}</Label>
                  </>
                ) : null}
                <BookingStatusLabel
                  className='mb-2'
                  bookingStatus={booking.status}
                  cancelledBy={booking.cancellationBy}
                />
              </Col>
              <Col>
                {serviceLocation ? (
                  <>
                    <strong className='d-block text-secondary'>Location</strong>
                    <Label className='text-break'>{serviceLocation.formattedAddress}</Label>
                  </>
                ) : null}
                {paymentOptions && (
                  <>
                    <strong className='d-block text-secondary'>Cost</strong>
                    <Label className='text-break'>{`${paymentOptions.name} - $${paymentOptions.cost}`}</Label>
                  </>
                )}
                <strong className='d-block text-secondary'>
                  EAP Client <HelpCircle.Booking partnerBooking={partnerBooking} />
                </strong>
                <Label className='d-block text-break whitespace-pre-line'>
                  {partnerBooking
                    ? `Yes - ${
                        therapyFundedBy?.toLowerCase() === 'unimed' ? 'UniMed' : partnerOrganisation
                      }` +
                      (caseNumber ? ` (${caseNumber}` : '') +
                      (caseSessionNumber ? ` #${caseSessionNumber}` : '') +
                      (caseNumber ? `)` : '')
                    : 'No - Public Booking'}
                  <br />
                  {/* {partnerBooking && <SessionLimitText contact={contact} />} */}
                </Label>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <HorizontalSeparator className='mt-2 mb-4'>Client Details</HorizontalSeparator>
        <Card className='bg-light mb-2'>
          <CardBody>
            <Row>
              <Col>
                <ListGroup>
                  {booking.type === 'Group'
                    ? this.renderGroups(appointments)
                    : this.renderContact(booking)}
                </ListGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  };

  getModalFooter = () => {
    const { booking, bookingStatus } = this.props;
    const bookingSource = (booking && booking.source) || '';

    if (booking.type === 'Group') {
      return (
        <ModalFooter>
          <Button color='secondary' onClick={this.editGroupBooking}>
            Edit
          </Button>
          <Button color='danger' onClick={this.deleteGroupAppointment} disabled={this.isDeleting}>
            Delete
          </Button>
        </ModalFooter>
      );
    }

    const isGroup = booking.groupId > 0;

    const ids = `${isGroup ? 'G-' : ''}${booking.id ? booking.id : '0'}-${
      booking.recurringAppointment ? booking.recurringAppointment.id : 0
    }`;

    if (bookingStatus === 'Block') {
      const disableUpdate = booking.readonly || bookingSource !== 'Clearhead';

      return (
        <ModalFooter className='justify-between'>
          <div></div>
          <div>
            {!disableUpdate && (
              <>
                {/* <Button
                color="secondary"
                onClick={this.convertBlockToAppointment}
              >
                Convert To Appointment
              </Button> */}
                <Button color='secondary' onClick={this.editBooking}>
                  Edit
                </Button>
                <Button color='danger' onClick={this.deleteBlock} disabled={this.isDeleting}>
                  Delete
                </Button>
              </>
            )}
          </div>
          {disableUpdate && <Label>This appointment can only be updated in {bookingSource}.</Label>}
        </ModalFooter>
      );
    } else if (bookingStatus === 'Manual') {
      return (
        <ModalFooter className='justify-between'>
          <div></div>
          <div>
            <Button
              color='danger'
              className='ml-2'
              onClick={this.rejectBooking}
              disabled={this.isAccepting}
            >
              Decline
            </Button>
            <Button
              color='success'
              className='ml-2'
              onClick={this.editBooking}
              disabled={this.isAccepting}
            >
              Accept
            </Button>
          </div>
        </ModalFooter>
      );
    } else if (bookingStatus === 'Pending') {
      return (
        <ModalFooter className='justify-between'>
          <div></div>
          <div>
            {!isGroup ? (
              <Button color='warning' onClick={this.editBooking} disabled={this.isAccepting}>
                Edit
              </Button>
            ) : null}
            <Button
              color='danger'
              className='ml-2'
              onClick={this.rejectBooking}
              disabled={this.isAccepting}
            >
              Decline
            </Button>
            <Button
              color='success'
              className='ml-2'
              onClick={this.acceptBooking}
              disabled={this.isAccepting}
            >
              Accept
            </Button>
          </div>
        </ModalFooter>
      );
    } else if (bookingStatus === 'Confirmed' || bookingStatus === 'PendingCompletion') {
      return (
        <ModalFooter className='justify-between items-center justify-items-center'>
          <InvoiceLink props={this.props} />
          <div>
            {booking.recurringAppointment ? (
              <ButtonDropdown isOpen={this.editDropdownOpen} toggle={this.toggleEditDropdown}>
                <DropdownToggle caret color='warning'>
                  Edit Options&nbsp;
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.editBooking}>Edit</DropdownItem>
                  <DropdownItem onClick={this.toggleRepeatModal}>Edit Recurrence</DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            ) : !isGroup ? (
              <Button color='warning' onClick={this.editBooking}>
                Edit
              </Button>
            ) : null}
            {booking.recurringAppointment ? (
              <ButtonDropdown
                isOpen={this.cancelDropdownOpen}
                toggle={this.toggleCancelDropdown}
                className='ml-2'
              >
                <DropdownToggle caret color='danger'>
                  Cancel Options&nbsp;
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={this.rejectReoccuringBooking}>
                    Cancel Appointment
                  </DropdownItem>
                  <DropdownItem
                    onClick={this.rejectReoccuringOnwards}
                    disabled={!booking.recurringAppointment}
                  >
                    Cancel Appointment Onwards
                  </DropdownItem>
                  <DropdownItem
                    onClick={this.rejectAllReoccuring}
                    disabled={!booking.recurringAppointment}
                  >
                    Cancel All Repeating
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            ) : (
              <Button color='danger' className='ml-2' onClick={this.rejectBooking}>
                Cancel Appointment
              </Button>
            )}
          </div>
        </ModalFooter>
      );
    } else if (bookingStatus === 'Completed') {
      return (
        <ModalFooter className='justify-start'>
          <InvoiceLink props={this.props} />
        </ModalFooter>
      );
    }

    return null;
  };

  toggleCancelDropdown = () => {
    this.cancelDropdownOpen = !this.cancelDropdownOpen;
  };

  toggleEditDropdown = () => {
    this.editDropdownOpen = !this.editDropdownOpen;
  };

  toggleEditAppointmentModal = () => {
    this.editAppointmentModalOpen = !this.editAppointmentModalOpen;
  };

  toggleRejectModal = () => {
    this.rejectModalOpen = !this.rejectModalOpen;
  };

  toggleRepeatModal = () => {
    this.repeatModalOpen = !this.repeatModalOpen;
  };

  acceptBooking = () => {
    const {
      booking,
      bookings: { acceptBooking },
      toggleModal,
    } = this.props;

    this.isAccepting = true;
    acceptBooking(booking).then((res) => {
      toggleModal();
      this.isAccepting = false;
    });
  };

  rejectBooking = () => {
    const { booking } = this.props;
    const mode = booking.status === 'Manual' ? 'Manual' : 'Appointment';
    this.rejectFormState = { appointmentId: booking.id, mode, reload: true };
    this.rejectModalOpen = true;
    this.props.toggleModal();
  };

  onRejectComplete = () => {
    this.rejectModalOpen = false;
    this.rejectFormState = {};
  };

  editBooking = () => {
    this.editAppointmentMode = 'edit';
    this.editAppointmentModalOpen = true;
    this.props.toggleModal();
  };

  editRecurrence = () => {
    this.repeatModalOpen = true;
    this.props.toggleModal();
  };

  convertBlockToAppointment = () => {
    this.editAppointmentMode = 'convert';
    this.editAppointmentModalOpen = true;
    this.props.toggleModal();
  };

  deleteGroupAppointment = () => {
    const { bookings, booking, toggleModal } = this.props;
    this.isDeleting = true;
    bookings.deleteGroupAppointment(booking).then((res) => {
      toggleModal();
      this.isDeleting = false;
    });
  };

  deleteBlock = (id) => {
    const { calendar, booking, toggleModal } = this.props;
    this.isDeleting = true;
    calendar.deleteBlock(booking).then((res) => {
      toggleModal();
      this.isDeleting = false;
    });
  };

  onEditRecurrenceComplete = (recurrence, actions) => {
    const { booking, bookings } = this.props;
    // The recurrence start date needs to begin from the date the recurrence is being edited on
    recurrence.recurrenceStartDate = moment(booking.appointmentTime).format('YYYY-MM-DD');

    bookings.editReoccuringBooking(recurrence).then((response) => {
      if (response.hasError) {
        response.errors.forEach((err) => {
          actions.setFieldTouched(err.field, true, false);
          actions.setFieldError(err.field, err.defaultMessage);
        });
        actions.setStatus({ message: response.message });
        toast.error(response.message);

        actions.setSubmitting(false);
      }

      const data = { pageOffset: 0, pageSize: 20 };
      bookings.getAllBookings(data).then(() => {
        this.repeatModalOpen = false;
      });
    });
  };

  // Reject a single reoccuring booking
  rejectReoccuringBooking = () => {
    const { bookings, booking, toggleModal } = this.props;
    if (booking.id) {
      this.rejectFormState = { appointmentId: booking.id };
      this.rejectModalOpen = true;
      this.props.toggleModal();
    } else {
      bookings.rejectReoccuringBooking(booking).then((res) => {
        toggleModal();
      });
    }
  };

  // Reject this and all reoccuring bookings onwards
  rejectReoccuringOnwards = () => {
    const { bookings, booking, toggleModal } = this.props;
    bookings.rejectReoccuringOnwards(booking).then((res) => {
      toggleModal();
    });
  };

  // Reject all non-complete reoccuring bookings in this sequence
  rejectAllReoccuring = () => {
    const { bookings, booking, toggleModal } = this.props;
    bookings.rejectAllReoccuring(booking).then((res) => {
      toggleModal();
    });
  };

  render() {
    const { isModalOpen, toggleModal, booking } = this.props;

    const currentDate = booking && booking.appointmentTime ? booking.appointmentTime : null;

    return (
      <>
        <Modal isOpen={isModalOpen} toggle={toggleModal} size='md' centered>
          <ModalHeader className='bg-light' toggle={toggleModal}>
            {this.getModalHeader()}
          </ModalHeader>
          <StatusAlert booking={booking} />
          <ModalBody className='p-3'>{this.getModalBody()}</ModalBody>
          {this.getModalFooter()}
        </Modal>

        <EditBookingModal
          mode={this.editAppointmentMode}
          isModalOpen={this.editAppointmentModalOpen}
          toggleModal={this.toggleEditAppointmentModal}
          booking={booking}
        />

        <RejectBookingModal
          isModalOpen={this.rejectModalOpen}
          toggleModal={this.toggleRejectModal}
          formState={this.rejectFormState}
          onComplete={this.onRejectComplete}
        />

        <RepeatModal
          mode='edit'
          isModalOpen={this.repeatModalOpen}
          currentDate={currentDate}
          toggleModal={this.toggleRepeatModal}
          onCancel={this.toggleRepeatModal}
          formState={booking.recurringAppointment}
          onComplete={this.onEditRecurrenceComplete}
        />
      </>
    );
  }
}

export default ViewBookingModal;
